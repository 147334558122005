export const GET_OLO_ATTRIBUTES = 'OLO_GET_OLO_ATTRIBUTES';
export const GET_OLO_BULK_ID_ATTRIBUTES = 'GET_OLO_BULK_ID_ATTRIBUTES';
export const GET_POS_MAPPING_DETAILS = 'OLO_GET_POS_MAPPING_DETAILS';
export const GET_STORE_SHUTDOWN_INFO = 'GET_STORE_SHUTDOWN_INFO';
export const UPDATE_OLO_SUCCESS_STATE = 'UPDATE_OLO_SUCCESS_STATE';
export const GET_SHUTDOWN_DETAILS = 'OLO_GET_SHUTDOWN_DETAILS';
export const SHUTDOWN_BATCH_ID = 'OLO_SHUTDOWN_BATCH_ID';
export const GET_PRODUCT_EXCLUSION = 'GET_PRODUCT_EXCLUSION';
export const EXCLUSION_BATCH_ID = 'EXCLUSION_BATCH_ID';
export const GET_STORE_HOLIDAY_INFO = 'GET_STORE_HOLIDAY_INFO';
export const HOLIDAY_BATCH_ID = 'HOLIDAY_BATCH_ID';
export const EXCLUSION_LOOKUP_ADVANCE = 'EXCLUSION_LOOKUP_ADVANCE';
export const CATEGORY_BASED_GROUP = 'CATEGORY_BASED_GROUP';
export const UPDATE_OUT_OF_STOCK = 'UPDATE_OUT_OF_STOCK';
export const OUTOFSTOCK_BATCH_ID = 'OUTOFSTOCK_BATCH_ID';
export const EXCLUSION_LOOKUP_DELETE_ADVANCE = 'EXCLUSION_LOOKUP_DELETE_ADVANCE';
export const OUTOFSTOCK_LOOKUP = 'OUTOFSTOCK_LOOKUP';
export const DELETE_OUTOFSTOCK_BATCH = 'DELETE_OUTOFSTOCK_BATCH';
export const UPDATE_DELETE_STATE = 'UPDATE_DELETE_STATE';
// export const SHUTDOWN_DELETE_MESSAGE = 'OLO_SHUTDOWN_DELETE_MESSAGE';
