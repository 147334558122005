import {
  GET_COMPANY_SETTINGS,
  POST_COMPANY_SETTINGS,
  GET_DRIVER_TIPPING,
  POST_DRIVER_TIPPING,
} from '../constants';

const initialState = {
  getCompanySettings: null,
  postCompanySettings: null,
  getDriverTipping: null,
  postDriverTipping: null,

};

export default function ConfigManagementReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY_SETTINGS:
      return {
        ...state,
        getCompanySettings: action.payLoad,
      };
    case POST_COMPANY_SETTINGS:
      return {
        ...state,
        postCompanySettings: action.payLoad,
      };
    case GET_DRIVER_TIPPING:
      return {
        ...state,
        getDriverTipping: action.payLoad,
      };
    case POST_DRIVER_TIPPING:
      return {
        ...state,
        postDriverTipping: action.payLoad,
      };
    default:
      return state;
  }
}
