import { CURRENT_LANGUAGE } from '../common/constants';
import { store } from '../store/store';

export const getTenantConfig = () => store.getState()?.LocalStoreReducer.tenantConfig;

export const updateCurrentLanguage = (lang) => (dispatch) => {
  dispatch({
    type: CURRENT_LANGUAGE,
    payLoad: lang,
  });
};

export const dispatchUpdateCurrentLanguage = async (lang) => {
  console.log(lang);
  await store.dispatch(updateCurrentLanguage(lang));
};
