import {
  GET_ITEMS_RECOMMENDATION,
  GET_BASKET_RECOMMENDATION,
  GET_PRODUCT_MASTER_DETAILS,
  UPDATE_PRODUCT_SUCCESS_STATE,
  GET_ALL_PRODUCT_RECOMMENDATIONS,
  GET_IMAGE_SUCCESS,
  IMAGE_BASE_URL,
  IMAGE_POST_URL,
  PUBLISH_IMAGE_BASE_URL,
  MENUPULL_ADDED,
  MENUPULL_FETCHED,
  MONITOR_FETCHED,
  CATALOG_FETCH,
  GET_ALL_PRODUCTDAY,
  UPDATE_PRODUCTDAY_SUCCESS,
  DELETE_PRODUCT_DAY_GROUP,
  PRODUCT_DAY_MENUS,
} from '../constants';

const initialState = {
  allItemsRecommendation: null,
  allBasketRecommendation: null,
  getProductMasterDetails: null,
  allProductRecommendation: null,
  successState: null,
  imageData: null,
  imageBaseURL: null,
  imagePostURL: null,
  publishImageUrl: null,
  menuPullAdded: null,
  menuPullFetched: null,
  monitorFetched: null,
  catalogStoreIds: null,
  getProductDay: null,
  postProductDay: null,
  deleteProductDay: null,
  productDayMenus: null,
};

export default function ProductManagmentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ITEMS_RECOMMENDATION:
      return {
        ...state,
        allItemsRecommendation: action.payLoad,
      };
    case GET_BASKET_RECOMMENDATION:
      return {
        ...state,
        allBasketRecommendation: action.payLoad,
      };
    case GET_ALL_PRODUCT_RECOMMENDATIONS:
      return {
        ...state,
        allProductRecommendation: action.payLoad,
      };
    case UPDATE_PRODUCT_SUCCESS_STATE:
      return {
        ...state,
        successState: action.payLoad,
      };
    case GET_PRODUCT_MASTER_DETAILS:
      return {
        ...state,
        getProductMasterDetails: action.payLoad,
      };
    case GET_IMAGE_SUCCESS:
      return {
        ...state,
        imageData: action.payLoad,
      };
    case IMAGE_BASE_URL:
      return {
        ...state,
        imageBaseURL: action.payLoad,
      };
    case IMAGE_POST_URL:
      return {
        ...state,
        imagePostURL: action.payLoad,
      };
    case PUBLISH_IMAGE_BASE_URL:
      return {
        ...state,
        publishImageUrl: action.payLoad,
      };
    case MENUPULL_ADDED:
      return {
        ...state,
        menuPullAdded: action.payLoad,
      };
    case MENUPULL_FETCHED:
      return {
        ...state,
        menuPullFetched: action.payLoad,
      };
    case MONITOR_FETCHED:
      return {
        ...state,
        monitorFetched: action.payLoad,
      };
    case CATALOG_FETCH:
      return {
        ...state,
        catalogStoreIds: action.payLoad,
      };
    case GET_ALL_PRODUCTDAY:
      return {
        ...state,
        getProductDay: action.payLoad,
      };
    case UPDATE_PRODUCTDAY_SUCCESS:
      return {
        ...state,
        postProductDay: action.payLoad,
      };
    case DELETE_PRODUCT_DAY_GROUP:
      return {
        ...state,
        deleteProductDay: action.payLoad,
      };
    case PRODUCT_DAY_MENUS:
      return {
        ...state,
        productDayMenus: action.payLoad,
      };
    default:
      return state;
  }
}
