import {
  filter, orderBy, map, reduceRight,
} from 'lodash';
import userManager from '../../store/UserManager';
import sidebarConfig from '../../config/sidebarConfigCopy.json';
import { all } from './constants/index';
// import i18n from '../../i18n';

export const getUserInfo = async () => {
  try {
    console.log('initiate get user.');
    const response = await userManager.getUser();
    // console.log('user.', response);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const isLoggedIn = async () => {
  const user = await getUserInfo();
  return user && user.access_token && !user.expired;
};

export const authenticateUser = (state) => {
  userManager.signinRedirect({
    data: {
      path: state || window.location.pathname,
    },
  });
};

export const revokeAuthentication = async () => {
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const logoutURL = process.env.REACT_APP_AUTHORITY_LOGOUT_URL;
  const ssoURL = process.env.REACT_APP_SSO_LOGOUT_URL;
  const userInfo = await getUserInfo();
  const oldLogoutUrl = `${logoutURL}/logout?client_id=${clientId}&logout_uri=${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/signout`;
  const newLogoutUrl = `${ssoURL}?appurl=${logoutURL}/logout?client_id=${clientId}%26logout_uri=${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/signout`;
  window.open(userInfo?.profile?.username.includes('_') ? newLogoutUrl : oldLogoutUrl, '_self');
};

const getSubNavigations = (navConfig, root) => {
  if (!navConfig || !navConfig[0] || !navConfig[0].subTitles || !root.menuDisplayName) return [];

  return orderBy(navConfig[0].subTitles.reduce((result, current) => {
    const resultClone = [...result];
    root.menuDisplayName[current.id] && resultClone.push({
      // eslint-disable-next-line prefer-template
      title: 'common.authPageTitle.' + current.id,
      id: current.id,
      url: current.url,
      sortOrder: root.menuDisplayName?.[current.id].sortOrder,
      isWritable: root.menuDisplayName?.[current.id]?.accessLevel?.toLowerCase() === all,
    });
    return resultClone;
  }, []), 'sortOrder');
};

export const getAuthorizedNavs = (response) => {
  if (!response) return null;

  let authorizedNavs = [];
  authorizedNavs.push({ ...sidebarConfig[0], title: 'common.authPageTitle.ExecutiveSummary' });

  authorizedNavs = [...authorizedNavs, ...(orderBy(response.displayNameDetails?.map((item) => {
    const navConfig = filter(sidebarConfig, { id: item.displayName });
    const subTitles = getSubNavigations(navConfig, item);
    return {
      // eslint-disable-next-line prefer-template
      title: 'common.authPageTitle.' + item.displayName,
      id: item.displayName,
      url: subTitles.length ? '' : navConfig?.[0]?.url,
      subTitles,
      sortOrder: parseInt(item.masterSortOrder, 10),
    };
  }), 'sortOrder'))];

  return authorizedNavs;
};

const getMatchedKeyRoutes = (allRoutes, userRoutes, key, hideLinkedRoute) => {
  const machedRoutes = filter(allRoutes, { uniqueKey: key });
  return machedRoutes.reduce((result, route) => {
    if (hideLinkedRoute && route.isLinkedRoute) return result;

    const resultClone = [...result];
    resultClone.push({
      ...route,
      aPIUrl: userRoutes[key].aPIUrl,
      sortOrder: userRoutes[key].sortOrder,
      accessLevel: userRoutes[key].accessLevel,
      isWritable: userRoutes[key]?.accessLevel?.toLowerCase() === all,
      // eslint-disable-next-line prefer-template
      pageName: 'common.authPageTitle.' + key,
    });

    return resultClone;
  }, []);
};

export const getAuthorizedRoutes = (routes, roles, hideLinkedRoute) => {
  if (!roles) return [];

  const userRoutes = reduceRight(map(roles.displayNameDetails, 'menuDisplayName'), (flattened, other) => (other ? { ...flattened, ...other } : flattened), {});
  let authRoutes = [];
  authRoutes.push({ ...routes[0], pageName: 'common.authPageTitle.ExecutiveSummary' });
  authRoutes = [
    ...authRoutes, ...(
      Object.keys(userRoutes).reduce(
        (result, key) => [
          ...result, ...getMatchedKeyRoutes(routes, userRoutes, key, hideLinkedRoute),
        ], [],
      )
    ),
  ];
  // authRoutes.push(routes[routes.length - 2]);
  authRoutes.push(routes[routes.length - 1]);
  return authRoutes;
};
