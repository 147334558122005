import SidebarArrow from './SidebarArrow';
import Sidebar from './Sidebar';
import logo from '../../assets/images/mainLogo.png';

const DesktopSidebar = (props) => (
  <div
    className="sidebar-main flex-column w-100 position-relative sidebar-transition d-flex d-none d-md-block"
    id="accordion"
  >
    <div className="mb-3 d-flex align-items-center justify-content-center px-4 pt-5 pb-1">
      <img src={logo} alt="logo" className="sidebarLogo" />
    </div>
    <div className="sidebar-brandLink d-none d-md-block text-uppercase text-center">KFC Canada Administration</div>
    <ul className="main-side-menu list-unstyled accordion text-capitalize">
      <Sidebar {...props} />
      <SidebarArrow arrowClass="closeAggregatedSideBar rotateButton" {...props} />
    </ul>
  </div>
);

export default DesktopSidebar;
